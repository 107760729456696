import React, { useEffect, useState } from 'react'
import Header from '../Layout/Header'
import InsidePageBanner from '../Component/InsidePageBanner'
import Footer from '../Layout/Footer'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, FormGroup, Modal, ToastContainer } from 'react-bootstrap'
import SEO from '../SeoComponent/Seo'
import { getapi, postapi } from '../Api/Api'

import Preloader from '../Component/PreLoder'
import { toast } from 'react-toastify'

function Jobdetail() {


    //form field
    const [portfolioUpload, setPortfolioupload] = useState(null)


    const [fullName, setFullName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [portfolioType, setPortfolioType] = useState('');
    const [gender, setGender] = useState('');
    const [address, setAddress] = useState('');
    const [desiredPosition, setDesiredPosition] = useState('');
    const [desiredSalary, setDesiredSalary] = useState('');
    const [highestEducation, setHighestEducation] = useState('');
    const [workExperience, setWorkExperience] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [portfolio, setPortfolio] = useState('');
    const [availabilityForInterview, setAvailabilityForInterview] = useState('');
    const [image, setImage] = useState(null);
    const [appliedJob, setAppliedJob] = useState([]);




    const handleChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'fullName':
                setFullName(value);
                break;
            case 'emailAddress':
                setEmailAddress(value);
                break;
            case 'phoneNumber':
                setPhoneNumber(value);
                break;
            case 'dateOfBirth':
                setDateOfBirth(value);
                break;
            case 'gender':
                setGender(value);
                break;
            case 'address':
                setAddress(value);
                break;
            case 'desiredPosition':
                setDesiredPosition(value);
                break;
            case 'desiredSalary':
                setDesiredSalary(value);
                break;
            case 'highestEducation':
                setHighestEducation(value);
                break;
            case 'workExperience':
                setWorkExperience(value);
                break;
            case 'companyName':
                setCompanyName(value);
                break;
            case 'portfolio':
                setPortfolio(value);
                break;
            case 'availabilityForInterview':
                setAvailabilityForInterview(value);
                break;
            case 'image':
                setImage(value);
                break;
            case 'portfolioUpload':
                setPortfolioupload(value);
                break;

            case 'appliedJob':
                setAppliedJob(value);
                break;
            default:
                break;
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setImage(file); // Store file object in state if needed
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        setPortfolioupload(file); // Store file object in state if needed
    };

    //form field

    const [show, setShow] = useState(false);
    const [content, setcontent] = useState([])
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [jobs, setjobs] = useState([])
    const { unique } = useParams()
    const navigate = useNavigate()
    const [loading, setloading] = useState(false)
    const handleget = async () => {
        let res = await getapi('jobs')

        setjobs(res?.data?.data)
    }

    useEffect(() => {
        handleget()
    }, [])

    const handleapply = (e, item) => {
        e.preventDefault()
        setShow(true)
        setAppliedJob(item._id)
        setDesiredPosition(item.title)
    }

    const handleinfo = async () => {
        setloading(true)
        let res = await getapi(`joburl/${unique}`)
        console.log(res)
        if (res?.data?.error == 0) {
            setloading(false)
            setcontent(res?.data?.data)
        } else {
            setloading(false)
        }

    }

    useEffect(() => {
        if (unique) {
            handleinfo()
        }
    }, [])

    const handleclear = () => {

        setFullName("");


        setEmailAddress("");

        setPhoneNumber("");

        setDateOfBirth("");

        setGender("");

        setAddress("");

        setDesiredPosition("");

        setDesiredSalary("");

        setHighestEducation("");

        setWorkExperience("");

        setCompanyName("");

        setPortfolio("");

        setAvailabilityForInterview("");

        setImage("");

        setAppliedJob("");
        setDesiredPosition("")
        setShow(false)

    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        setloading(true)
        const formData = new FormData();
        formData.append('fullName', fullName);
        formData.append('emailAddress', emailAddress);
        formData.append('phoneNumber', phoneNumber);
        formData.append('dateOfBirth', dateOfBirth);
        formData.append('gender', gender);
        formData.append('address', address);
        formData.append('desiredPosition', desiredPosition);
        formData.append('desiredSalary', desiredSalary);
        formData.append('highestEducation', highestEducation);
        formData.append('workExperience', workExperience);
        formData.append('companyName', companyName);
        formData.append('portfolio', portfolio);
        formData.append('availabilityForInterview', availabilityForInterview);
        formData.append('image', image);
        formData.append('icon', portfolioUpload);

        formData.append('applied_job', appliedJob);

        try {
            // Make API request to save formData to MongoDB
            const response = await postapi('applyjob', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.data.error == 0) {
                setloading(false)
                toast.success(response.data.message)
                handleclear()
            }
        } catch (error) {
            console.error('Error submitting application:', error);
            // Handle error notification or feedback
            toast.error("Application Not Sent")
        }
    };

    const closedJobs = jobs?.filter((item) => item.url !== unique);
    const today = new Date();
    const minDate = new Date();
    minDate.setFullYear(today.getFullYear() - 10);
    const maxDate = today.toISOString().split('T')[0];
    const formatTitle = (str) => {
        return str
            .replace(/[-/]/g, ' ')           // Replace hyphens and slashes with spaces
            .split(' ')                      // Split the string into words
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
            .join(' ');                      // Join the words back into a single string
    };

    return (
        <>

            {loading && <Preloader />}
            <SEO title="Job Detail" description="Welcome to our Job Detail page." />
            <Header />
            <InsidePageBanner title="Job Detail" />
            <section className=' jobdetailpage'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="jobcontent">
                                        <h3>{formatTitle(unique)}</h3>
                                        <div className="specification ">
                                            <span>{content.jobtype} </span>
                                            <span>{content.officetype} </span>
                                            <span>{content.experience} </span>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="text-end">
                                        <button className=' text-white rounded-3 btn px-4 shadow-none' style={{ background: "#3f56a3" }} onClick={(e) => handleapply(e, content)}>Apply Now</button>
                                    </div>
                                    {/* <button className='btn bg-white shadow ms-2 '><i class="fas fa-share-alt"></i></button> */}
                                </div>
                            </div>
                            <div className="mt-4">
                                <h4>About this role</h4>
                                <div className="text-justify">
                                    <div className="" dangerouslySetInnerHTML={{ __html: content.about }}></div>
                                </div>
                            </div>
                            <div className="mt-4">
                                <h4>Qualification</h4>
                                <div className="" dangerouslySetInnerHTML={{ __html: content.qualification }}></div>
                            </div>
                            <div className="mt-4">
                                <h4>Responsibilty</h4>
                                <div className="" dangerouslySetInnerHTML={{ __html: content.responsibility }}></div>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <h3 className=''>More Jobs</h3>
                            <div className="row gy-3">
                                {jobs?.slice(0, 3).reverse().map((item, index) => {
                                    return (
                                        <div key={index}>
                                            {item.url !== unique && (
                                                <div className="col-md-12">
                                                    <div className="bg-white shadow p-3 rounded-3">
                                                        <h5>{item.title}</h5>
                                                        <h6>Required Experience : 2-3 Years</h6>
                                                        <div className="specification">
                                                            {item.officetype === "Office" ? (
                                                                <span>
                                                                    <i className="fas fa-briefcase"></i> {item.officetype}
                                                                </span>
                                                            ) : (
                                                                <span>
                                                                    <i className="fas fa-home"></i> {item.officetype}
                                                                </span>
                                                            )}
                                                        </div>
                                                        <div className="text-end">
                                                            <button
                                                                className="btn main-btn applynow shadow-none"
                                                                onClick={() => navigate(`/job-detail/${item.url}`)}
                                                            >
                                                                Apply Now
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                                <div className="col-md-12">


                                    {closedJobs?.length > 3 && (
                                        <div className="text-center m-4">
                                            <button
                                                className="btn btn main-btn applynow shadow-none"
                                                onClick={() => navigate('/career')}
                                            >
                                                View All
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

            <Modal show={show} size='lg' onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Apply For {desiredPosition ?? "This Job Role"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <div className="row gy-2 p-3">
                            <div className="col-md-6">
                                <label htmlFor="fullName" className="fw-bold">Full Name <span className='text-danger'>*</span></label>
                                <input type="text" id="fullName" name="fullName" className="form-control" value={fullName} onChange={handleChange} required />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="emailAddress" className="fw-bold">Email Address <span className='text-danger'>*</span></label>
                                <input
                                    type="email"
                                    id="emailAddress"
                                    name="emailAddress"
                                    className="form-control"
                                    value={emailAddress}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="phoneNumber" className="fw-bold">Phone Number <span className='text-danger'>*</span></label>
                                <input
                                    type="tel"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    className="form-control"
                                    value={phoneNumber}
                                    minLength={10}
                                    maxLength={10}
                                    onChange={(e) => {
                                        // Remove any non-numeric characters
                                        const numericValue = e.target.value.replace(/\D/g, '');
                                        // Update state with numeric value only
                                        handleChange({ target: { name: e.target.name, value: numericValue } });
                                    }}
                                    required
                                    pattern="\d{10}"
                                    title="Phone number must be exactly 10 digits and contain only numbers"
                                    placeholder="Enter 10-digit phone number"
                                />
                            </div>

                            <div className="col-md-6">
                                <label htmlFor="dateOfBirth" className="fw-bold">Date of Birth <span className='text-danger'>*</span></label>
                                <input
                                    type="date"
                                    id="dateOfBirth"
                                    name="dateOfBirth"
                                    className="form-control"
                                    value={dateOfBirth}
                                    onChange={handleChange}
                                    required
                                    // Minimum date: 10 years before today
                                    max={maxDate} // Maximum date: today
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="gender" className="fw-bold">Gender <span className='text-danger'>*</span></label>
                                <select id="gender" name="gender" className="form-select" value={gender} onChange={handleChange} required>
                                    <option value="">Select Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="address" className="fw-bold">Address <span className='text-danger'>*</span></label>
                                <input type="text" id="address" name="address" className="form-control" value={address} onChange={handleChange} required />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="desiredPosition" className="fw-bold">Desired Position <span className='text-danger'>*</span></label>
                                <input type="text" id="desiredPosition" readOnly name="desiredPosition" className="form-control" value={desiredPosition} onChange={handleChange} required />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="desiredSalary" className="fw-bold">Desired Salary <span className='text-danger'>*</span></label>
                                <input type="number" id="desiredSalary" name="desiredSalary" className="form-control" value={desiredSalary} onChange={handleChange} required />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="highestEducation" className="fw-bold">Highest Education <span className='text-danger'>*</span></label>
                                <input type="text" id="highestEducation" name="highestEducation" className="form-control" value={highestEducation} onChange={handleChange} required />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="workExperience" className="fw-bold">Work Experience <span className='text-danger'>*</span></label>
                                <input type="text" id="workExperience" name="workExperience" className="form-control" value={workExperience} onChange={handleChange} required />
                            </div>

                            {desiredPosition.toLowerCase().includes("graphic") && (
                                <>
                                    <div className="col-md-6">
                                        <label htmlFor="portfolioType" className="fw-bold">How would you like to provide your portfolio?</label>
                                        <select
                                            id="portfolioType"
                                            name="portfolioType"
                                            className="form-select"
                                            value={portfolioType}
                                            onChange={(e) => setPortfolioType(e.target.value)}
                                        >
                                            <option value="" disabled>Select an option</option>
                                            <option value="upload">Upload a File</option>
                                            <option value="link">Provide a Link</option>
                                        </select>
                                    </div>

                                    {portfolioType === 'upload' && (
                                        <div className="col-md-6 mt-3">
                                            <label htmlFor="portfolioUpload" className="fw-bold">Upload Portfolio <span className='text-danger'>*(Only Pdf)</span></label>
                                            <input
                                                type="file"
                                                id="portfolioUpload"
                                                name="portfolioUpload"
                                                className="form-control"
                                                accept=".pdf"
                                                onChange={(e) => handleFileUpload(e)}
                                            />
                                        </div>
                                    )}

                                    {portfolioType === 'link' && (
                                        <div className="col-md-6 mt-3">
                                            <label htmlFor="portfolioLink" className="fw-bold">Portfolio Url</label>
                                            <input
                                                type="text"
                                                id="portfolio"
                                                name="portfolio"
                                                className="form-control"
                                                value={portfolio} onChange={handleChange}
                                                placeholder="Enter portfolio URL"
                                            />
                                        </div>
                                    )}


                                </>
                            )}

                            <div className="col-md-6">
                                <label htmlFor="companyName" className="fw-bold">Company Name <span className='text-danger'>*</span></label>
                                <input type="text" id="companyName" name="companyName" className="form-control" value={companyName} onChange={handleChange} required />
                            </div>




                            <div className="col-md-6 mt-3">
                                <label htmlFor="availabilityForInterview " className="fw-bold">Availability for Interview <span className='text-danger'>*</span></label>
                                <input type="date" id="availabilityForInterview" name="availabilityForInterview" className="form-control" value={availabilityForInterview} onChange={handleChange} required />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="image" className="fw-bold">Upload Resume <span className='text-danger'>*(Only Pdf)</span></label>
                                <input type="file" className="form-control" accept='.pdf' onChange={(e) => handleFileChange(e)} required />
                            </div>
                            <div className="col-md-12">
                                <div className="mt-2">
                                    <button type="submit" className="btn text-white px-4" style={{ background: "#f46c02" }}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default Jobdetail



