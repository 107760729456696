import React from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import SEO from '../SeoComponent/Seo';
import InsidePageBanner from '../Component/InsidePageBanner';

function PrivacyPolicy() {
    return (
        <>
            <SEO title="Privacy Policy" description="Welcome to Happening Ads Privacy Policy page." />

            <Header />
            <InsidePageBanner title="Privacy Policy" />
            <section className='my-3 '>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className='mt-5'>Privacy Policy</h2>
                            <p>Welcome to Happening Ads. We value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and safeguard your data when you interact with our services.</p>

                            <h3>Information We Collect</h3>
                            <p>We may collect the following types of information:</p>
                            <ul>
                                <li><strong>Personal Information:</strong> Name, email address, phone number, and other contact details when you sign up for our services or contact us.</li>
                                <li><strong>Usage Data:</strong> Information on how you interact with our website, such as IP address, browser type, pages visited, and time spent on our site.</li>
                                <li><strong>Cookies and Tracking Technologies:</strong> We use cookies and similar technologies to enhance your experience and gather information about how you use our site.</li>
                            </ul>

                            <h3>How We Use Your Information</h3>
                            <p>Your information is used to:</p>
                            <ul>
                                <li>Provide, maintain, and improve our services.</li>
                                <li>Communicate with you, including responding to your inquiries and sending promotional materials.</li>
                                <li>Analyze usage and trends to better understand how our services are used and to develop new features.</li>
                                <li>Ensure the security of our website and prevent fraud.</li>
                            </ul>

                            <h3>Sharing Your Information</h3>
                            <p>We do not sell your personal information. We may share your data with:</p>
                            <ul>
                                <li>Service providers who assist us in operating our business, such as email service providers and analytics companies.</li>
                                <li>Legal authorities if required by law or to protect our rights and comply with legal proceedings.</li>
                            </ul>

                            <h3>Data Security</h3>
                            <p>We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no internet transmission is completely secure, and we cannot guarantee absolute security.</p>

                            <h3>Your Choices</h3>
                            <p>You have the right to:</p>
                            <ul>
                                <li>Access and update your personal information.</li>
                                <li>Opt-out of receiving promotional communications from us.</li>
                                <li>Request the deletion of your personal information, subject to certain legal exceptions.</li>
                            </ul>

                            <h3>Changes to This Privacy Policy</h3>
                            <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and we will notify you of significant changes.</p>

                            <h3>Contact Us</h3>
                            <p>If you have any questions or concerns about this Privacy Policy, please contact us at <strong>support@happeningads.com</strong>.</p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default PrivacyPolicy;
