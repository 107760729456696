import React, { useEffect, useState } from 'react';
import heroLine from '../assets/images/hero-line.png';
import article1 from '../assets/images/article-1.jpg';
import article2 from '../assets/images/article-2.jpg';
import article3 from '../assets/images/article-3.jpg';
import BlogsCard from '../Component/BlogsCard';
import { Link } from 'react-router-dom';
import { getapi } from '../Api/Api';


const articles = [
    {
        id: 1,
        title: 'The Clear Difference Between Cold Brew & Iced Coffee',
        date: '25 JUN',
        image: article1,
        description: 'DNP Installations can carry out all maintenance on phone and data and phone points in...',
        link: '/article-detail',
    },
    {
        id: 2,
        title: 'Your Small Business Success From Marketing',
        date: '25 JUN',
        image: article2,
        description: 'DNP Installations can carry out all maintenance on phone and data and phone points in...',
        link: '/article-detail',
    },
    {
        id: 3,
        title: 'The Start-Up Ultimate Guide Make Wordpress Journal.',
        date: '25 JUN',
        image: article3,
        description: 'DNP Installations can carry out all maintenance on phone and data and phone points in...',
        link: '/article-detail',
    },
    {
        id: 4,
        title: 'The Start-Up Ultimate Guide Make Wordpress Journal.',
        date: '25 JUN',
        image: article3,
        description: 'DNP Installations can carry out all maintenance on phone and data and phone points in...',
        link: '/allarticles',
    },
];



const ArticleSection = () => {
    const [data, setdata] = useState([])
    const handleblogsget = async () => {
        let res = await getapi(`blogs`)
        console.log("blogs", res)
        setdata(res.data.data)

    }

    useEffect(() => {
        handleblogsget()
    }, [])



    return (
        <section className="article-area">
            
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-9">
                        <div className="section-title text-center">
                            <img loading="lazy" src={heroLine} alt="" />
                            <h3 className="title">Recent Article</h3>
                            <p>Our focus is on delivering you the absolute best support guiding you through training and providing.</p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    {/* {articles.slice(0, 3).map((article, index) => (
                        <BlogsCard key={index} data={article} />
                    ))} */}
                    <BlogsCard data={data} />
                </div>
            </div>
        </section>
    );
};

export default ArticleSection;
