import React, { useEffect, useState } from 'react';
import heroLine from '../assets/images/hero-line.png';
import ServiceCard from '../Component/ServiceCard';
import MainSerCard from '../Component/MainSerCard';
import { getapi } from '../Api/Api';
import Typer from '../Component/Typer';

function OurService() {
  const [branddata, setbranddata] = useState([])
  const [digitaldata, setdigitaldata] = useState([])
  const [eventdata, seteventdata] = useState([])







  const getdata = async () => {
    const res = await getapi('subservice/667bb95edc60fc4769fc2a4b')

    setbranddata(res?.data?.data)
    console.log(res.data)
  }

  const getdigital = async () => {
    const res = await getapi('subservice/667bb9a3dc60fc4769fc2a4f')

    setdigitaldata(res?.data?.data)
  }

  const getevent = async () => {
    const res = await getapi('subservice/667bb9a9dc60fc4769fc2a51')

    seteventdata(res?.data?.data)
  }

  useEffect(() => {
    getdata()
    getdigital()
    getevent()
  }, [])


  return (
    <>
      <section className="service-area pb-100" id="services">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-9">
              <div className="section-title text-center">
                <img loading="lazy" src={heroLine} alt="Hero Line" />
                <h3 className="title">Our Service</h3>
                {/* <p className="typing-animation">

                </p> */}
                <Typer
                  dataText={[
                    "We deliver the support you need through our integrated services, with a flawless design, simplistic approach and elegant style."
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="row justify-content-center gy-4">
            {/* <ServiceCard data={data} /> */}
            <MainSerCard data={branddata} maintitle={branddata[0]?.service[0]?.name} url={branddata[0]?.service[0]?.url} mainid={branddata[0]?.service[0]?._id} />
            <MainSerCard data={digitaldata} maintitle={digitaldata[0]?.service[0]?.name} url={digitaldata[0]?.service[0]?.url} mainid={digitaldata[0]?.service[0]?._id} />
            <MainSerCard data={eventdata} maintitle={eventdata[0]?.service[0]?.name} mainid={eventdata[0]?.service[0]?._id} url={eventdata[0]?.service[0]?.url} />
          </div>
        </div>
      </section>
    </>
  )
}

export default OurService

