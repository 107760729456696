import React, { Component, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { getapi, postapi } from '../Api/Api'
import { toast } from 'react-toastify'

function ContactUsModal(props) {
    const [name, setname] = useState("")
    const [email, setemail] = useState("")
    const [number, setnumber] = useState("")
    const [location, setlocation] = useState("")
    const [service, setservice] = useState("")

    const [message, setmessage] = useState("")

    const [data, setdata] = useState([])

    const handleget = async () => {
        let res = await getapi('service')
        console.log(res)
        setdata(res.data.data)
    }

    useEffect(() => {
        handleget()
    }, [])
    const handleclear = () => {
        setname("")
        setemail("")
        setnumber("")
        setlocation("")
        setmessage("")
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        let requestdata = {
            name: name,
            email: email,
            number: number,
            service: service,
            location: location,
            message: message
        }
        let res = await postapi('contact', requestdata)

        if (res.data.error == 0) {
            if (props.check) {
                props.setShow(false);
            }
            toast.success(res.data.message)
            handleclear()
        } else {
            toast.error("Data not Submit")
        }
    }


    return (
        <>
            <div className="row justify-content-center">
                <div className="col-lg-12">
                    <div className="contact-us-box mr-0 p-4"  >

                        <Form onSubmit={(e) => handleSubmit(e)}>
                            <div className="row no-gutters">
                                <div className="col-lg-6">
                                    <div className="input-box mt-10">
                                        <input type="text" value={name} onChange={(e) => setname(e.target.value)} placeholder="Your name" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="input-box mt-10">
                                        <input type="email" value={email} onChange={(e) => setemail(e.target.value)} placeholder="Email address" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="input-box mt-10">
                                        <input type="text" minLength={10} maxLength={10} value={number} onChange={(e) => setnumber(e.target.value)} placeholder="Number" pattern="[0-9]*" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="input-box mt-10">
                                        <select name="" value={service} onChange={(e) => setservice(e.target.value)} id="">
                                            <option value="">Select Service</option>
                                            {data.map((item) => {
                                                return (
                                                    <>
                                                        <option value={item.name}>{item.name}</option>
                                                    </>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="input-box mt-10">
                                        <input type="text" value={location} onChange={(e) => setlocation(e.target.value)} placeholder="Location" />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="input-box mt-10">
                                        <textarea name="#" value={message} onChange={(e) => setmessage(e.target.value)} id="#" cols="30" rows="10" placeholder="Write message"></textarea>
                                    </div>
                                    <ul className="checkbox_common checkbox_style5">
                                        <li>
                                            <input type="checkbox" name="checkbox5" id="checkbox13" />
                                            <label htmlFor="checkbox13"><span></span>I agree to the data protection regulations</label>
                                        </li>
                                    </ul>
                                    <div className="input-box">
                                        <button className="main-btn" type="submit">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactUsModal
