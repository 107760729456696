import React, { useEffect, useState } from 'react'
import Header from '../../Layout/Header'
import Footer from '../../Layout/Footer'
import InsidePageBanner from '../../Component/InsidePageBanner'
import { useLocation, useParams } from 'react-router-dom'
import ServiceCard from '../../Component/ServiceCard'
import heroLine from '../../assets/images/hero-line.png';
import SEO from '../../SeoComponent/Seo'

import { getapi } from '../../Api/Api'

import Preloader from '../../Component/PreLoder'













function Servicedetail() {
    const { unique } = useParams()
    const [loading, setloading] = useState(false)
    const { state } = useLocation()
    const [data, setdata] = useState([])

    const getdata = async () => {
        setloading(true)
        let res = await getapi(`subservice/${state}`)
        console.log(res)
        if (res?.data?.error == 0) {
            setloading(false)
            setdata(res?.data?.data)
        } else {
            setloading(false)
        }
    }

    useEffect(() => {
        getdata()
    }, [])







    return (
        <>
            {loading && <Preloader />}
            <SEO title="Service Detail" description="Welcome to our Service Detail page." />
            <Header />
            <InsidePageBanner title={"Services"} />
            <section>
                <div className="container">
                    <div className="row  gy-4 mb-5">
                        <div className="col-md-12">
                            <div className="section-title text-center mt-4">
                                <img loading="lazy" src={heroLine} alt="Hero Line" />
                                <h3 className="title text-uppercase">{unique} </h3>

                            </div>
                        </div>
                        <ServiceCard detail={unique}
                            // data={unique == "digital" ? digitaldata : unique == "brand" ? branddata : eventdata}
                            data={data}
                        />
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Servicedetail
