import React, { useEffect, useState } from 'react';
import logo from '../assets/images/logo.png'; // Make sure to replace with your actual logo path
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import ContactUsModal from '../Component/ContactUsModal';

const Header = () => {
    const [isActive, setIsActive] = useState(false);
    const [mobilemenu, setmobilemenu] = useState(false)


    const location = useLocation()

    const otherpage = location.pathname == '/'


    const [show, setShow] = useState(false);

    //one day close modal
    // const handleClose = () => {
    //     setShow(false);
    //     const now = new Date();
    //     const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0); // Set end of day
    //     const millisecondsUntilEndOfDay = endOfDay - now;

    //     sessionStorage.setItem('modalclose', 'true');

    //     // Clear session storage item at the end of the day
    //     setTimeout(() => {
    //         sessionStorage.removeItem('modalclose');
    //     }, millisecondsUntilEndOfDay);
    // };


    const handleClose = () => {
        setShow(false);

        // Calculate milliseconds until the end of the day
        const millisecondsInDay = 24 * 60 * 60 * 1000; // Total milliseconds in a day
        const now = new Date();
        const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0); // Next day at 00:00:00
        const millisecondsUntilEndOfDay = endOfDay - now;

        sessionStorage.setItem('modalclose', 'true');

        // Clear session storage item at the end of the day
        setTimeout(() => {
            sessionStorage.removeItem('modalclose');
        }, millisecondsUntilEndOfDay);
    };


    const handleShow = () => setShow(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    useEffect(() => {

    }, []);

    const handlecheck = () => {
        const modalClosed = sessionStorage.getItem('modalclose');

        if (modalClosed === 'true') {
            setShow(false);
        } else {
            setShow(true);
            // Optionally, you can set 'modalclose' to true here if needed
            // sessionStorage.setItem('modalclose', 'true');
        }
    }


    useEffect(() => {
        const handleScroll = () => {
            const scroll = window.scrollY;
            if (scroll < 110) {
                setIsActive(false);

                handlecheck()
            } else {
                setIsActive(true);

            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const navigate = useNavigate()



    const handleToggleClick = () => {
        setmobilemenu(!mobilemenu);
    };

    const handleNavLinkClick = () => {
        setIsActive(false);
    };

    const subMenuItems = [
        // {
        //     id: 1,
        //     title: 'Home',
        //     link: '/'
        // },
        {
            id: 2,
            title: 'About',
            link: `/about`
        },
        {
            id: 3,
            title: 'Service',
            link: `${otherpage ? "#services" : "/"}`
        },
        {
            id: 4,
            title: 'Portfolio',
            link: `${otherpage ? "#portfolio" : "/"}`
        },
        {
            id: 5,
            title: 'career',
            link: '/career'
        },
        // {
        //     id: 5,
        //     title: 'Pricing',
        //     link: '/pricing'
        // },
        {
            id: 6,
            title: 'Contact',
            link: '/contact'
        }
    ];

    return (
        <>
            <header className="header-area ">
                <div className={`header-nav ${isActive ? "sticky" : ""}`}>
                    <div className={`${otherpage ? "container" : "container-fluid"}`}>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="navigation">
                                    <nav className="navbar navbar-expand-lg navbar-light ">
                                        {!otherpage && <div className="backbtn me-md-5 me-2">
                                            <button className="btn px-3 rounded-3 " onClick={(e) => navigate(-1)}><i class="fa-solid fa-angle-left"></i> </button>
                                        </div>}
                                        <Link className="navbar-brand" to='/'><img loading="lazy" className='' src={logo} alt="Logo" /></Link>

                                        <button className={`navbar-toggler ${mobilemenu ? 'active' : ''}`} type="button" onClick={handleToggleClick}>
                                            <span className="toggler-icon"></span>
                                            <span className="toggler-icon"></span>
                                            <span className="toggler-icon"></span>
                                        </button>

                                        <div className={`collapse navbar-collapse sub-menu-bar  ${mobilemenu ? 'show' : ''}`} id="navbarSupportedContent">
                                            <ul className="navbar-nav ml-auto">
                                                {subMenuItems.map(item => (
                                                    <li className="nav-item" key={item.id}>
                                                        <a className="nav-link" href={item.link} onClick={handleNavLinkClick}>{item.title}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>

                                        <div className={`${otherpage ? "navbar-btn d-none d-sm-block" : "navbar-btn d-none d-sm-block pe-5"}`}>
                                            <a className="main-btn" href="#" onClick={(e) => setShow(true)}>Get Started</a>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <Modal show={show} size='lg' className='book-open' centered onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Contact Us</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ContactUsModal show={show} setShow={setShow} check={true} />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Header;
